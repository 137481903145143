<template>
  <h2 v-html="$t('productionformrows_search_production_approval_header')"></h2>
  <!-- Status -->

  <v-select
    v-if="this.statuses.length > 0"
    id="status"
    v-model="searchCardStore.status"
    :label="$t('productionformrows_search_production_approval_status')"
    :items="statuses"
    item-value="name"
    :item-title="this.$getLangKey()"
    clearable
    v-on:keyup.enter="search"
  />

  <v-select
    v-if="this.animalTypesProduction.length > 0"
    id="search_production_animalType"
    required
    v-model="searchCardStore.animalTypeId"
    :items="animalTypesProduction"
    item-value="id"
    :item-title="this.$getLangKey()"
    :label="$t('productionformrows_search_production_animalType')"
    clearable
    v-on:keyup.enter="search"
  />

  <DbmMonthPicker
    v-model="searchCardStore.validFrom"
    :dateLabel="$t('productionformrows_search_production_validFrom')"
    :clearable="true"
    returnType="firstOfMonth"
  />

  <DbmMonthPicker
    v-model="searchCardStore.validUntil"
    :dateLabel="$t('productionformrows_search_production_validUntil')"
    :clearable="true"
    returnType="lastOfMonth"
  />

  <v-select
    id="zvm"
    v-model="searchCardStore.subsidyApproved"
    :label="$t('productionformrows_search_production_approval_zvm')"
    item-value="value"
    :items="zvm_options"
    item-title="text"
    clearable
    v-on:keyup.enter="search"
  />

  <!-- expanded-->
  <div class="link align-right" v-on:click="expanded = !expanded">
    <span v-if="!expanded" v-html="$t('more')"></span>
    <span v-else v-html="$t('less')"></span>
  </div>
  <div v-if="expanded">
    <!-- Art -->
    <v-select
      id="search_production_isCorrection"
      v-model="searchCardStore.isCorrection"
      :label="$t('productionformrows_search_production_isCorrection')"
      :items="isCorrection_options"
      item-title="text"
      item-value="value"
      clearable
      @keyup.enter="search"
    />
  </div>
</template>

<script lang="ts">
import baseSearchVue from './baseSearch.vue'
import { apiURL, app } from '../../main'
import { showError } from '@/services/axios'
import DbmMonthPicker from '@/components/dbmMonthPicker.vue'
import { useAnimalTypesStore } from '@/store/enums/AnimalTypesStore'
import { useSearchProductionStore } from '@/store/SearchProductionStore'

import { defineComponent } from 'vue'
export default defineComponent({
  name: 'searchProductionApproval',
  extends: baseSearchVue,
  components: { DbmMonthPicker },
  data() {
    return {
      expanded: false,
      datepickerExpanded: false,
      app: app,
      menuFrom: false,
      menuTo: false,
      statuses: [],
      zvm_options: [
        {
          id: 0,
          text: this.$t('productionformrows_search_production_approval_all'),
          value: null
        },
        {
          id: 1,
          text: this.$t('productionformrows_search_production_approval_yes'),
          value: 'APPROVED'
        },
        {
          id: 2,
          text: this.$t('productionformrows_search_production_approval_no'),
          value: 'NOT_APPROVED'
        },
        {
          id: 3,
          text: this.$t('productionformrows_search_production_approval_none'),
          value: 'NONE'
        }
      ],
      isCorrection_options: [
        { value: null, id: 0, text: this.$t('productionformrows_all') },
        {
          value: false,
          id: 1,
          text: this.$t('productionformrows_erstmeldungen')
        },
        { value: true, id: 2, text: this.$t('productionformrows_korrekturen') }
      ]
    }
  },
  computed: {
    animalTypesProduction() {
      return useAnimalTypesStore().AnimalTypesProduction
    }
  },
  methods: {
    async getProductionFormStatuses() {
      try {
        const result = await this.axios.get(apiURL + '/productionFormRows/status', {}, { headers: { 'Content-Type': 'application/json' } })
        this.statuses = result.data
      } catch (e) {
        showError(e)
      }
    }
  },
  beforeMount() {
    this.setSearchCardStore(useSearchProductionStore(), true)
    useAnimalTypesStore().fetchAnimalTypesProduction()
    this.getProductionFormStatuses()
  }
})
</script>
